import React, { Component } from 'react';
import './MintYourPet.css'
import axios from 'axios';
import Web3 from 'web3';
import Footer from '../Footer';

class MintYourPetPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingState: 0, //0 is ready, 1 is in progress, 2 is error
            loadingProgress: 0, //0 is nothing, done or error, 1 is mm, 2 is db, 3 is pic, 4 is success (if neeed),
            successMessage : null,
            image: null
        }

    }


    componentDidMount() {
        window.addEventListener('beforeunload', this.beforeunload.bind(this));
    }

    beforeunload(e) {
        if (this.state.loadingState === 1) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    async payForNFTMint(e) {
        e.preventDefault();
        this.setState({ loadingProgress: 0 });
        this.setState({ loadingProgress: 1 });
        let formData = new FormData(e.target);
        const formProps = Object.fromEntries(formData);


        //checking file extension
        let file = formProps.fileupload;
        let filename = file.name;
        let ext = filename.split('.').pop();
        if (ext !== 'png' && ext !== "jpg" && ext !== "jpeg" && ext !== "gif") {
            return alert("Only file types EXT, PNG, JPG/JPEG allowed. Sorry!");
        }
        //check file size
        if (file.size > 100000000) {
            return alert("File Must not exceed 100MB")
        }

        //fix jpeg / jpg
        if (ext === "jpg") {
            ext = "jpeg";
        }

        e.preventDefault();
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        window.web3 = new Web3(window.ethereum);
        this.setState({ account: accounts[0] });
        const networkIdHex = await window.ethereum.networkVersion;
        const hexToDecimal = hex => parseInt(hex, 16);
        const networkId = hexToDecimal(networkIdHex);


        //used for testing
        if(accounts[0] == 0x10a37CceC40F638078D41C0DddFa71C07F740332){
            this.setState({price : "100000000000000000"})
        } else {
            this.setState({price : "200000000000000000000"})
        }

        try {
            if (networkId !== 361) {
                alert("Please connect to the ThetaNetwork in Metamask");
            } else {
                this.setState({ loadingState: 1 });
                this.setState({ network: 361 });

                let result = await window.web3.eth.sendTransaction({
                    from: accounts[0],
                    to: '0x10a37CceC40F638078D41C0DddFa71C07F740332',
                    value: this.state.price, //200 TFUEL
                    gasLimit: '1000000',
                });

                this.setState({ loadingProgress: 2 });

                this.storeDataInDB(formProps, ext, result.blockHash);
            }
        } catch (e) {
            alert("Error with metamask");
            console.log(e);
            this.setState({ loadingState: 0 });
            this.setState({ loadingProgress: 0 });
        }

    }

    async storeDataInDB(e, ext, tx) {
        const apiLink = 'https://p6h2ii4or6.execute-api.us-east-1.amazonaws.com/V1/neworder';
        const date = new Date();
        const jsonData = JSON.stringify({
            "email": e['email'],
            "OrderDate": date,
            "petName": e['name'],
            "wallet": this.state.account,
            "fileType": ext,
            "tx": tx
        })

        await axios.post(apiLink, jsonData).then(response => {
            let file = e.fileupload;
            let link = response.data;
            this.uploadFile(file, link);

        });

        this.setState({ loadingProgress: 2 });

    }

    async uploadFile(file, link) {
        this.setState({ loadingProgress: 3 });
        await axios.put(link, file, {
            headers: {
                "Content-Type": file.type
            }
        });

        this.setState({ loadingProgress: 4 });
        this.setState({ loadingState: 0 });

        alert("Success! Please allow up to 24 hours for your NFT to appear in your wallet! If you have any questions feel free to email apatkos22@gmail.com. Thank you!")
    }


    setImage(e) {
        var file = document.getElementById('fileupload').files[0];
        var src = URL.createObjectURL(file)

        this.setState({ image: src });
    }


    render() {
        return (
            <div className='mint-your-pet-page-main'>
                <div className='mint-your-pet-page-sub'>
                    <h1 style={{ fontWeight: '800' }}>Mint Your Pet!</h1>
                    <h3 style={{ fontWeight: '600' }}>200 TFuel</h3>
                    <div className='mint-your-pet-main-row'>
                        <div className='mint-your-pet-left'>
                            <img src='./Theta Blue.png' alt='Theta Logo'></img>
                        </div>
                        <div className='mint-your-pet-center'>
                            <form onSubmit={(e) => this.payForNFTMint(e)}>
                                <div className='mint-your-pet-form-text'>
                                    <label>Email: &nbsp;</label>
                                    <input type='email' id='email' name='email' placeholder="Email" required ></input>
                                    <br />
                                    <label>Pet Name: &nbsp;</label>
                                    <input type='text' id='name' name='name' placeholder="Pet Name" required ></input>
                                    <br />
                                    <label>Chose File:&nbsp;</label>  {/*This needs to be styled so there's no white side */}
                                    <input id="fileupload" className="fileupload" type="file" name="fileupload" onChange={(e) => this.setImage(e)} />
                                </div>
                                <br />
                                {
                                    this.state.image &&
                                    <div className='mint-your-pet-preview' style={{ textAlign: 'center' }}>
                                        <img src={this.state.image} alt='uploaded preview'></img>
                                    </div>

                                }
                                {
                                    (this.state.loadingState === 1 && this.state.loadingProgress === 1) &&
                                    <p style={{ textAlign: 'center', margin: 'auto' }}>Loading.....See Metamask 1/4</p>
                                }
                                {
                                    (this.state.loadingState === 1 && this.state.loadingProgress === 2) &&
                                    <p style={{ textAlign: 'center', margin: 'auto' }}>Submitting Information...2/4</p>
                                }
                                {
                                    (this.state.loadingState === 1 && this.state.loadingProgress === 2) &&
                                    <p style={{ textAlign: 'center', margin: 'auto' }}>Uploading Pic...3/4</p>
                                }
                                {
                                    (this.state.loadingState === 1 && this.state.loadingProgress === 2) &&
                                    <p style={{ textAlign: 'center', margin: 'auto' }}>Success!... 4/4</p>
                                }

                                {
                                    (this.state.loadingState === 0) &&
                                    <button id="upload-button" onSubmit={(e) => this.payForNFTMint(e)}>Mint My Pet! 200 TFuel</button>
                                }
                                {
                                    (this.state.loadingState === 1) &&
                                    <button id="upload-button" onSubmit={(e) => this.payForNFTMint(e)}>Loading...Don't Click Away!</button>
                                }
                                {
                                    this.state.loadingState === 2 &&
                                    <button id="upload-button">Error</button>
                                }
                            </form>
                        </div>
                        <div className='mint-your-pet-right'>
                            <img src='./mm.png' alt='Metamask Logo'></img>
                        </div>
                    </div>
                    <p>Once submitted your image will be uploaded to our servers. <br />From there one of our team members will be uploading your image to Arweave then turning it into a NFT! <br /> The NFT will then be sent to the wallet used to sign this transaction. <br /> From there you pet will be on the blockchain...FOREVER!</p>
                </div>
                <div>
                    <Footer />
                </div>
            </div>

        )
    }
}

export default MintYourPetPage;
