import React, { Component } from 'react';

class FAQs extends Component {


    render() {
        return (
            <div className='faqs-home'>
                <div className='faqs-home-sub'>
                    <h1>About:</h1>
                    <p>Hey, my name is Austin. I'm the founder of The Stealing Hearts Foundation. The Foundation is in memorium of my best friend, Bandit.
                        Bandit was the sweetest pup and had this way of making everyone fall in love with her. A 'bandit' by definition steals things, in her case she stole the hearts of everyone she met.
                        In late 2021 unfortunately  Bandit crossed over the Rainbow Ridge and passed on. At the time I was absolutely crushed by this. Spontaneously, I drove to Home Depot got 2 giant cardboard boxes and then filled them up at Petsmart and filing them with toys and blankets for a local animal shelter.
                        This is the spirit of Stealing Hearts and exactly why I made it. 
                        <br></br>To honor all those animals that steal our hearts just like Bandit did. 
                        <br/>
                        <i>Thank you all so much!</i></p>
                </div>
                <div className='faqs-home-img-container'>
                    <img src='./img/austin.jpg' alt='Austin'></img>
                    <img src='./img/bandit-austin.jpg'  alt='Austin + Bandit'></img>
                    <img src='./img/bandit.jpg' alt='Bandit'></img>
                </div>
                <br/>
                {/* <a href='./audits' target="_blank" style={{color:'black'}}>Click Here for Audits</a> */}
            </div>

        )
    }
}

export default FAQs;
