import React, { Component } from 'react';
import './Thankyou.css';

class Thankyou extends Component {


    render() {
        return (
            <div>
                <div className='break-bar-thank-you'></div>
                <div className='thank-you-main'>
                <h1>Thank you!</h1>
                <img src='./img/main-logo.png' alt='main logo'></img>
                <div className='thank-you-body-text'>
                    <p>I can't express how much your support means to us. I want you to know that with donation will go far to assisting animal shelters and animals in need of help.
                        There's a lot of scared and alone animals out there, some of which are in pain. But it's because of your generosity and others like you that we can take some of that pain away.
                        From the bottom of my heart, I thank you so very much for caring. Thank you for honoring all those animals that steal our hearts just like Bandit did.
                        <br /> -You're the best!
                    </p>
                </div>
                </div>
            </div>

        )
    }
}

export default Thankyou;
