import React, { Component } from 'react';
import "./Audits.css"

class Audits extends Component {


    render() {
        return (
            <div className='audits-home'>
                <div className='audits-sub'>
                    As we receive donations we intend to be as transparent as possible. 
                    <br/>On this page we will be posting receipts and audits of all possible transactions.
                </div>
            </div>

        )
    }
}

export default Audits;
