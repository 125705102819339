import React, { Component } from 'react';
import './Home.css'
import Footer from './Footer';
import DirectDonation from './DirectDonation';
import MintYourPet from './MintYourPet';
import FiftyFifty from './FiftyFifty';
import Merch from './Merch';
import SpecializedNFTMarket from './SpecializedNFTMarket'
import FAQs from './FAQs';


class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            account: '0x0',
            page: 0
        }

    }





    render() {
        return (
            <div className='home'>
                <div className='main-img-container'>
                    <img src='./img/main-logo.png' alt='main'></img>
                </div>
                <div className='home-basic-info'>
                    <h1><i>Helping Those Who Steal Our Hearts, One Animal At a Time.</i></h1>
                </div>
                <DirectDonation/>
                {/* <MintYourPet/>
                <FiftyFifty/> 
                <SpecializedNFTMarket/> */}
                <Merch/>
                <FAQs/>
                <div className='home-footer-container'>
                    <Footer />
                </div>
            </div>

        )
    }
}

export default Home;
