import React, { Component } from 'react';
import copy from 'copy-to-clipboard';

class DirectDonation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            copied : "Click To Copy This Address"
        }

    }


    async copyAddress(e) {
        e.preventDefault();
        this.setState({copied: "Copied!"});
        copy("0x75050DFA49633c9955d74022fD13DEA2bB94fc16");
    }


    render() {
        return (
            <div className='direct-donation-main'>
                <h1 className='direct-donation-title'>Direct Donation</h1>
                <div className='direct-donation-sub'>
                    <a href='https://www.paypal.com/donate/?hosted_button_id=PBCWF8U3QPE36' target="_blank" style={{ textDecoration: 'none' }}  rel="noopener noreferrer">
                        <div className='direct-donation-paypal'>
                            <img src='./paypal logo.png' alt='Paypal Logo'></img>
                            <h1>PayPal Donation</h1>
                            <p>(Donate Directly Through PayPal)</p>
                            <button>Click to Be Taken To PayPal's Site</button>

                        </div>
                    </a>
                    <br />

                    <div className='direct-donation-theta'>
                        <img src='./Theta White.png' alt='Theta Logo'></img>
                        <h1>TFuel Donation</h1>
                        <p className='direct-address' id='address'>0x75050DFA49633c9955d74022fD13DEA2bB94fc16</p>
                        <button onClick={(e)=>this.copyAddress(e)}>{this.state.copied}</button>
                        <p>Please ONLY send TFUEL to this Address any other token will be lost.</p>
                    </div>
                </div>
            </div>

        )
    }
}

export default DirectDonation;


//0x75050DFA49633c9955d74022fD13DEA2bB94fc16
