import React, { Component } from 'react';


class SpecializedNFTMarket extends Component {

    render() {
        return (
            <div className='NFT-market-main'>
                <div className='NFT-market-main-header'>
                    <h1>Specialized NFT Market!</h1>
                    <p>These NFTs were designed by creators on the Theta Ecosystem. They will only be available for this Charity event. Get yours before they're gone!</p>
                </div>
                <div className='nft-market-main-creator-row'>
                    <a href='https://thetapugs.com/' target="_blank" rel='noopener noreferrer'><h1>Creator: Theta Pugs</h1></a>
                    <div className='nft-market-main-creator-row-content-container'>
                        <img src='./creators/thetaPugs/Elf-Pug.jpg' alt='Theta Pugs Elf Pug'></img>
                        <img src='./creators/thetaPugs/Reindeer-Pug.jpg' alt='Theta Pugs Reindeer Pug'></img>
                        <img src='./creators/thetaPugs/Santa-Pug.jpg' alt='Theta Pugs Santa Pug'></img>
                        <img src='./creators/thetaPugs/Stealing-Hearts-Pug.jpg' alt='Theta Pugs Stealing Hearts Pug'></img>
                        <button>Coming Soon!</button>
                    </div>
                </div>
            </div>

        )
    }
}

export default SpecializedNFTMarket;