import React, { Component } from 'react';

class Merch extends Component {


    render() {
        return (
            <div className='merch-home'>
                <h1>Merch</h1>
                <div className='merch-home-sub'>
                    <div className='merch-product'>
                        <div className='merch-product-sub'>

                            <a href='https://www.amazon.com/dp/B0BKH27H1T?customId=B07536XX75&th=1' target='_blank' rel="noopener noreferrer">
                                <img src='./img/stealing hearts shirt.jpg' alt='Stealing Hearts Shirt'></img>
                                <div className='merch-product-description'>
                                    <p>Stealing Hearts T-Shirt</p>
                                    <p>$16.99</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='merch-product'>
                        <div className='merch-product-sub'>
                            <a href='https://www.amazon.com/dp/B0BL95ND9L' target='_blank' rel="noopener noreferrer">
                                <img src='./img/popSocket.jpg' alt='Stealing Hearts Pop Socket'></img>
                                <div className='merch-product-description'>
                                    <p>Stealing Hearts PopSockets PopGrip</p>
                                    <p>$14.99</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='merch-product'>
                        <a href='https://www.amazon.com/dp/B0BL981WJP' target='_blank' rel="noopener noreferrer">
                            <img src='./img/iphoneCase.jpg' alt='Stealing Hearts iPhone Case'></img>
                            <div className='merch-product-description'>
                                <p>iPhone 12/12 Pro Stealing Hearts Case</p>
                                <p>$17.99</p>
                            </div>
                        </a>
                    </div>
                    <div className='merch-product'>
                        <a href='https://www.amazon.com/dp/B0BL9FNWRS' target='_blank' rel="noopener noreferrer">
                            <img src='./img/galaxyPhoneCase.jpg' alt='Stealing Hearts Galaxy Case'></img>
                            <div className='merch-product-description'>
                                <p>Galaxy S20 Stealing Hearts Case</p>
                                <p>$17.99</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

        )
    }
}

export default Merch;
