import React, { Component } from 'react';

class MintYourPet extends Component {


    mintYourPetPage(e){
        e.preventDefault()
        window.location.href = "./mintYourPet";
    }


    render() {
        return (
            <div className='mint-your-pet-home'>
                <div className='mint-your-pet-home-sub'>
                    <div className='mint-your-pet-home-img'>
                        <img src='./Theta White.png' alt='Theta Logo'></img>
                    </div>
                    <div className='mint-your-pet-home-text'>
                        <h1>Turn Your Pet Into a NFT!</h1>
                        <p>Using the power of The Theta Network you can turn your Pet into a NFT!</p>
                    </div>
                    <div className='mint-your-pet-home-button-container'>
                        <button onClick={(e)=>this.mintYourPetPage(e)}>NFT of My Pet!</button>
                    </div>
                </div>
            </div>

        )
    }
}

export default MintYourPet;
