import React, { Component } from 'react';
import Web3 from 'web3';
import StealingHeartsRaffle from '../truffle_abis/StealingHeartsRaffle.json'


class FiftyFifty extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mintLoading: 0, //0 is nothing, 1 is loading, 2 is done 
            newContractInfo: '0xb5ea919f9b9f4e1564a02ddf172866e70e3ca69d',
            totalEntryTickets: "TBA",
            totalPool: "TBA",
            totalPayout : "TBA"
        }

    }

    async componentDidMount() {
        try {
            const web3 = new Web3(new Web3.providers.HttpProvider("https://eth-rpc-api.thetatoken.org/rpc"));

            const StealingHeartsRaffleContract = await new web3.eth.Contract(StealingHeartsRaffle.abi, '0x84900ac589b064272aa1e940ab1e333d96c47a81');

            let totalEntryTickets = await StealingHeartsRaffleContract.methods.totalSupply().call();
            totalEntryTickets--;
            this.setState({ totalEntryTickets });

            let totalPool = await web3.eth.getBalance('0x84900ac589b064272aa1e940ab1e333d96c47a81');
            totalPool = totalPool / 10**18;
            this.setState({totalPool});

            let totalPayout = totalPool / 2;
            this.setState({totalPayout});

        } catch (err) {
            alert(err);
        }
    }

    async mintNewNFT(e) {
        e.preventDefault();
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        window.web3 = new Web3(window.ethereum);
        this.setState({ account: accounts[0] });
        const networkIdHex = await window.ethereum.networkVersion;
        const hexToDecimal = hex => parseInt(hex, 16);
        const networkId = hexToDecimal(networkIdHex);

        try {
            if (networkId !== 361) {
                alert("Please connect to the ThetaNetwork in Metamask");
            } else {
                this.setState({ mintLoading: 1 });
                this.setState({ network: 361 }); 
                const StealingHeartsRaffleContract = await new window.web3.eth.Contract(StealingHeartsRaffle.abi, '0x84900ac589b064272aa1e940ab1e333d96c47a81');
                this.setState({StealingHeartsRaffleContract})

                await StealingHeartsRaffleContract.methods.mintTo().send({
                    from: accounts[0],
                    gasLimit: '1000000',
                    value: '50000000000000000000',
                });
                this.setState({ mintLoading: 0 });
                alert('Order Successfully Sent to the Network, please allow a few minutes for the transaction to process. See Metamask for More Details');
            }
        } catch (e) {
            alert("Error with metamask");
            console.log(e);
            this.setState({ mintLoading: 0 });
        }
    }

    

    render() {
        return (
            <div className='fifty-50-home'>
                <div className='fifty-50-home-title'>
                    <h1>50/50 Raffle Contest!</h1>
                    <h2>Win TFuel and Help Support the Cause at the Same time!</h2>
                </div>
                <div className='fifty-50-sub'>
                    <div>
                        <img src='./img/bandit1.png' alt='Bandit Pic'></img>
                    </div>
                    <br />
                    <div>
                        <div className='fifty-50-contest-info'>
                            <p>Total Entry Tickets: {this.state.totalEntryTickets}</p>
                            <p>Total Pool: {this.state.totalPool} TFUEL</p>
                            <p>Total Payout To Winner: {this.state.totalPayout} TFUEL</p>
                            {
                                this.state.mintLoading === 0 &&
                                <button onClick={(e) => this.mintNewNFT(e)}>Buy Ticket <br /> 50 TFuel</button>
                            }
                             {
                                this.state.mintLoading === 1 &&
                                <button>Loading...</button>
                            }
                        </div>
                        <br />
                        <div className='fifty-50-description'>
                            This 50/50 is powered by the Theta Network. <br />The contest is set up to run on the blockchain and tickets are sold as NFTs (how cool is that?)!!
                            The rules are simple, 1 NFT gets you one ticket. The winner is randomly selected at the end of the donation period.
                        </div>
                    </div>

                </div>
            </div>

        )
    }

}

export default FiftyFifty;

/*
pragma solidity ^0.4.17;
    
  contract Raffle {
      address public organizer;
      address[] public players;
        
      function Raffle() public{
          organizer = msg.sender;
      }
       
      function enter() public payable{
          require(msg.value > .01 ether);
          players.push(msg.sender);
      }
       
      function random() private view returns(uint){
        return uint(keccak256(block.difficulty, now, players));
      }
       
      function pickWinner() public{
          uint index = random() % players.length;
          players[index].transfer(this.balance);
      }
  }*/