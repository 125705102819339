import React, {useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import ReactGa from 'react-ga'; 
import Audits from './Audits';
import Thankyou from './Thankyou';
import MintYourPetPage from './MintYourPet/MintYourPet';

function App(){

    useEffect(() =>{
        ReactGa.initialize('G-6BYFMPJ7G0')
        ReactGa.pageview('/')
    }, [])

        return (
            <div className='app-main'>
                <Router>
                    <Routes>
                        <Route exact path='/' element={<Home />} />
                        <Route exact path='/mintYourPet' element={<MintYourPetPage/>}/>
                        <Route exact path='/audits' element={<Audits/>} />
                        <Route exact path='/thankyou' element={<Thankyou/>} />
                        <Route exact path='/*' element={<Home />} />
                    </Routes>
                </Router>
            </div>

        )
}

export default App;


